export class QuestionsFakeData {
    public static questions = [
      {
        id: 1,
        questionNo: '1',
        questionName: 'Yotz PVT LTD',
        answers: ['options','options','options'],
        subquestions: 'gslixby0',
        options: 'El Salvador',
      },
      {
        id: 2,
        questionNo: '2',
        questionName: 'Yotz PVT LTD',
        answers: ['options'],
        subquestions: 'gslixby0',
        options: 'El Salvador',
      },
      {
        id: 3,
        questionNo: '3',
        questionName: 'Yotz PVT LTD',
        answers: ['options'],
        subquestions: 'gslixby0',
        options: 'El Salvador',
      },
      {
        id: 4,
        questionNo: '4',
        questionName: 'Yotz PVT LTD',
        answers: ['options'],
        subquestions: 'gslixby0',
        options: 'El Salvador',
      },
      {
        id: 5,
        questionNo: '5',
        questionName: 'Yotz PVT LTD',
        answers: ['options'],
        subquestions: 'gslixby0',
        options: 'El Salvador',
      },
      {
        id: 6,
        questionNo: '6',
        questionName: 'Yotz PVT LTD',
        answers: ['options'],
        subquestions: 'gslixby0',
        options: 'El Salvador',
      },
      {
        id: 7,
        questionNo: '7',
        questionName: 'Yotz PVT LTD',
        answers: ['options'],
        subquestions: 'gslixby0',
        options: 'El Salvador',
      }
    ];
  }
  