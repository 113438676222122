export class Contact_usFakeData {
    public static contents = [
      {
        id: 1,
        register_Content: 'description',
        Faq_content: "sasafd"        
      },
      
      {
        id: 2,
        register_Content: 'mailingAddress',
        Faq_content:"asfdafadfda"
      },
      {
        id: 3,
        register_Content: 'corporateAddress',
        Faq_content:"asfdasdsdf"
      }
    ];
  }
  
  