export class ContentFakeData {
    public static contents = [
      {
        id: 1,
        register_Content: 'height',
        options: ['El Salvador','El Salvador'],
        Faq_content: "terms_and_conditions"        
      },
      
      {
        id: 2,
        register_Content: 'religion',
        options: ['El Salvador','El Salvador'],
        Faq_content:"how_it_works"
      },
      {
        id: 3,
        register_Content: 'Belongto',
        options: ['El Salvador','El Salvador'],
        Faq_content:"how_to_block_person"
      },
      {
        id: 4,
        register_Content: 'occupation',
        options: ['El Salvador','El Salvador'],
        Faq_content:"how_to_report_person"
      },
      {
        id: 5,
        register_Content: 'postalcode',
        options: ['El Salvador','El Salvador'],
        Faq_content:"how_deactivat_myaccount"
      }
    ];
  }
  
  




// how_to_cancel_subscription
// want_to_stop_renewal
// payment_related_query
// other