export class SubscriptionFakeData {
    public static subscriptions = [
      {
        id: 1,
        subscriptionType:'Free Plan',
        subscriptionPrice:"free"
      },
      {
        id: 2,
        subscriptionType:'Standard Plan',
        subscriptionPrice:"$7/Month"

      },
      {
        id: 3,
        subscriptionType:'Premium Plan',
        subscriptionPrice:"$15/Month"
      },
    ];
  }